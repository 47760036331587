<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link
          class="text-decoration--none"
          :to="{ name: 'course-categories' }"
          >Course Category</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4">
      <div class="align-center d-flex justify-space-between">
        <div class="display-1 font-weight-bold my-6 text-capitalize">
          Update Course Category
        </div>
        <template v-if="!loading"> </template>
      </div>

      <div class="col-xl-4 col-lg-6 col-md-12 mt-6 pa-0">
        <v-form v-if="form" @submit.prevent="submit" ref="form">
          <v-divider class="pb-5"></v-divider>

          <label class="text-field-label">Course Category Name</label>
          <v-text-field
            flat
            solo
            required
            counter
            class="mt-2"
            maxlength="255"
            v-model="form.label"
            :error-messages="form.$getError('label')"
          ></v-text-field>
          <v-divider class="my-5"></v-divider>
          <div class="mt-4">
            <v-btn
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="70%"
              @click="submit"
              :loading="form.$busy"
              ><v-icon left dark> {{ icons.update }} </v-icon>Update</v-btn
            >
          </div>
          <div class="mt-4">
            <v-btn
              @click="showDeleteModal = true"
              height="40px"
              width="70%"
              color="error"
              ><v-icon left dark> {{ icons.remove }} </v-icon>Delete Course
              Category</v-btn
            >
          </div>
        </v-form>
      </div>
    </div>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>
    <ConfirmModal
      v-model="showDeleteModal"
      title="Delete Category"
      message="Are you sure you want to delete category?"
      @cancel="showDeleteModal = false"
      @confirm="removeCategory"
    ></ConfirmModal>
  </div>
</template>

<script>
import Form from '@/utils/form'
import { mdiChevronLeft, mdiTrashCan, mdiUpdate } from '@mdi/js'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mapActions, mapState, mapMutations } from 'vuex'
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
  name: 'CoursecourseCategoryDetails',

  components: {
    AppBarNavIcon,
    ConfirmModal,
  },

  data() {
    return {
      form: null,
      showDeleteModal: false,
      icons: {
        arrowLeft: mdiChevronLeft,
        remove: mdiTrashCan,
        update: mdiUpdate,
      },
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
    }
  },

  computed: {
    ...mapState({
      courseCategoryDetails: (state) =>
        state.courseCategory.courseCategoryDetails,
    }),
  },

  methods: {
    ...mapActions({
      updateCourseCategory: 'courseCategory/updateCourseCategory',
      getCourseCategoryDetails: 'courseCategory/getCourseCategoryDetails',
      deleteCourseCategory: 'courseCategory/deleteCourseCategory',
    }),

    ...mapMutations({
      clearCourseCategoryDetails: 'courseCategory/clearCourseCategoryDetails',
    }),

    async getCourseCategory() {
      this.loading = true
      await this.getCourseCategoryDetails(this.$route.params.id)
      this.form = new Form(this.courseCategoryDetails)
      this.loading = false
    },

    async submit() {
      this.form.$busy = true
      this.form.$clearErrors()

      this.updateCourseCategory(this.form.$data())
        .then(() => {
          this.showSnackbar('Course Category successfully updated!', 'success')

          setTimeout(() => {
            this.$router.push({
              name: 'course-categories',
            })
          }, 1000)
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    removeCategory() {
      this.loading = true

      this.deleteCourseCategory(this.$route.params.id)
        .then(() => {
          this.$router.push({ name: 'course-categories' })
        })
        .catch((error) =>
          this.showSnackbar(error.response.data.message, 'error')
        )
        .finally(() => (this.loading = false))
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },
  },

  created() {
    this.getCourseCategory()
  },

  destroyed() {
    this.clearCourseCategoryDetails()
  },

  watch: {
    $route() {
      this.getCourseCategory()
    },
  },
}
</script>
